var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"simple-dropdown"},[_c('div',{staticClass:"simple-dropdown_label",on:{"click":function($event){$event.stopPropagation();return _vm.toggleIsDropdownOpen.apply(null, arguments)}}},[_c('p',{class:{ placeholder: !_vm.selectedItem }},[_vm._v(" "+_vm._s(_vm.selectedItem ? _vm.itemDisplayKey ? _vm.selectedItem[_vm.itemDisplayKey] : _vm.selectedItem : _vm.placeholderText)+" ")]),_c('i',{staticClass:"fas fa-chevron-down",class:{ 'rotated-up': _vm.isDropdownOpen }})]),(_vm.isDropdownOpen)?_c('div',{staticClass:"arrow"}):_vm._e(),(_vm.isDropdownOpen)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeIsDropdownOpen),expression:"closeIsDropdownOpen"}],staticClass:"simple-dropdown_items"},_vm._l((_vm.items),function(item){return _c('div',{key:_vm.itemDisplayKey ? item[_vm.itemDisplayKey] : item,staticClass:"simple-dropdown_items_item",class:{
        selected: _vm.itemDisplayKey
          ? _vm.selectedItem[_vm.itemDisplayKey] === item[_vm.itemDisplayKey]
          : _vm.selectedItem === item,
      },on:{"click":function($event){return _vm.selectItem(item)}}},[_c('p',[_vm._v(_vm._s(_vm.itemDisplayKey ? item[_vm.itemDisplayKey] : item))]),_c('i',{staticClass:"fas fa-check",class:{
          shown: _vm.itemDisplayKey
            ? _vm.selectedItem[_vm.itemDisplayKey] === item[_vm.itemDisplayKey]
            : _vm.selectedItem === item,
        }})])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }